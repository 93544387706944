//
import { API_DOMAIN, ATLAS_API_DOMAIN, AUTH_API_DOMAIN, EHR_URL } from 'constants/api';

import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  Section,
  Standard,
  HorizontalRule,
  Subtitle,
  Headline,
  Content,
  Card,
  Floaty,
  Form,
  Input,
  Select,
} from '@spring/smeargle';
import envUtils from 'utils/environment';
import { LandingPageWrapper } from 'components/templates/Landing';
import routes from 'routes';
import { Container } from '@springcare/sh-component-library';

const setEnv = (rotom, diglet, ehr, atlas) => {
  localStorage.setItem('rotom', rotom);
  localStorage.setItem('diglet', diglet);
  localStorage.setItem('ehr', ehr);
  localStorage.setItem('atlas', atlas);

  window.location = window.location.href.split('?')[0];
};

const resetEnv = () => {
  localStorage.removeItem('rotom');
  localStorage.removeItem('diglet');
  localStorage.removeItem('ehr');
  localStorage.removeItem('atlas');

  window.location.reload(false);
};

const Index = () => {
  const router = useRouter();
  const localhostRotom = 'http://localhost:4400';
  const localhostDiglet = 'http://localhost:8081';
  const localhostEHR = 'http://localhost:5500';
  const localhostAtlas = 'http://localhost:4000';
  const perfLabRotom = 'https://app-40260.on-aptible.com';
  const perfLabDiglet = 'https://app-40265.on-aptible.com';
  const perfLabEHR = 'https://app-47558.on-aptible.com';
  const perfLabAtlas = ''; //  Not yet deployed

  const codespacesRotom = process.env.CODESPACE_NAME
    ? `https://${process.env.CODESPACE_NAME}-4400.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
    : '';
  const codespacesDiglet = process.env.CODESPACE_NAME
    ? `https://${process.env.CODESPACE_NAME}-8081.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
    : '';
  const codespacesEhr = process.env.CODESPACE_NAME
    ? `https://${process.env.CODESPACE_NAME}-5500.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
    : '';
  const codespacesAtlas = process.env.CODESPACE_NAME
    ? `https://${process.env.CODESPACE_NAME}-4401.${process.env.GITHUB_CODESPACES_PORT_FORWARDING_DOMAIN}`
    : '';

  /**
   * NOTE: See https://humanwhocodes.com/blog/2010/01/12/history-of-the-user-agent-string/ for why
   * we can't just check if the UA includes 'Safari'
   */
  const isLocalhostDisabled = !navigator.userAgent.includes('Firefox') && !navigator.userAgent.includes('Chrome');

  useEffect(() => {
    if (envUtils.redirectShayminHomepageToSignIn) {
      router.replace(routes.SignIn.to, routes.SignIn.as);
    } else if (router.query && router.query.rotom && router.query.diglet) {
      setEnv(decodeURIComponent(router.query.rotom), decodeURIComponent(router.query.diglet));
    }
  }, [process.env.APP_ENV]);

  return (
    <>
      {!envUtils.magicEnvToggleEnabled && null}
      {envUtils.magicEnvToggleEnabled && (
        <LandingPageWrapper>
          <Standard>
            <Container maxW="container.md">
              <Headline noMargin>Magic ENV Toggle</Headline>
              <Subtitle noMargin status="warn">
                This page will only show up on non-production environments
              </Subtitle>

              <Section>
                <HorizontalRule />
              </Section>

              <Content>These forms can help you target any api. By default it will use {process.env.APP_API}.</Content>

              <Section>
                <Content bold>
                  Working on the backend? Use "Localhost" in the Quick Swap drop-down to use the default ports or
                  manually adjust as needed. Unfortunately,{' '}
                  <a href="https://developer.mozilla.org/en-US/docs/Web/Security/Mixed_content#loading_locally_delivered_mixed-resources">
                    Safari does not support locally-delivered mixed-content
                  </a>
                  , so this won't work.
                </Content>
              </Section>
              <Section size="lg">
                <Floaty radius={3} float={1}>
                  <Card white>
                    <Subtitle noMargin>Quick Swap</Subtitle>
                    <Form
                      formKey="auto-env-change"
                      initialData={{
                        rotom: API_DOMAIN,
                        diglet: AUTH_API_DOMAIN,
                        ehr: EHR_URL,
                        atlas: ATLAS_API_DOMAIN,
                        env: 'staging',
                      }}
                      onSubmit={({ env }) => {
                        switch (env) {
                          case 'localhost':
                            return setEnv(localhostRotom, localhostDiglet, localhostEHR, localhostAtlas);
                          case 'perf-lab':
                            return setEnv(perfLabRotom, perfLabDiglet, perfLabEHR, perfLabAtlas);
                          case 'codespaces':
                            return setEnv(codespacesRotom, codespacesDiglet, codespacesEhr, codespacesAtlas);
                          default:
                            return setEnv(
                              `https://care-api.${env}.springtest.us`,
                              `https://auth-api.${env}.springtest.us`,
                              `https://ehr-api.${env}.springtest.us`,
                              `https://atlas.${env}.springtest.us`
                            );
                        }
                      }}
                    >
                      <Select
                        label="Select an ENV"
                        fieldKey="env"
                        options={[
                          { label: 'Staging', value: 'staging' },
                          { label: 'Localhost', value: 'localhost', disabled: isLocalhostDisabled },
                          { label: 'Performance Lab', value: 'perf-lab' },
                          { label: 'Codespaces', value: 'codespaces' },
                          { label: 'AWS Development', value: 'dev' },
                        ]}
                      />
                    </Form>
                  </Card>
                </Floaty>
              </Section>

              <Section>
                <Floaty radius={3} float={1}>
                  <Card white>
                    <Subtitle noMargin>Manual Swap</Subtitle>
                    <Form
                      formKey="manual-env-change"
                      initialData={{
                        rotom: API_DOMAIN,
                        diglet: AUTH_API_DOMAIN,
                        ehr: EHR_URL,
                        atlas: ATLAS_API_DOMAIN,
                      }}
                      onSubmit={({ rotom, diglet, ehr, atlas }) => {
                        setEnv(rotom, diglet, ehr, atlas);
                      }}
                      actions={[
                        {
                          color: 'error',
                          icon: 'trash-can',
                          text: 'Reset',
                          onClick: () => {
                            resetEnv();
                          },
                        },
                      ]}
                    >
                      <Input fieldKey="rotom" label="rotom base url" />
                      <Input fieldKey="diglet" label="diglet base url" />
                      <Input fieldKey="ehr" label="ehr base url" />
                      <Input fieldKey="atlas" label="atlas base url" />
                    </Form>
                  </Card>
                </Floaty>
              </Section>
            </Container>
          </Standard>
        </LandingPageWrapper>
      )}
    </>
  );
};

export default Index;
